export const getLocationFetch = () => {
	// /api?action=location
	// https://smi2.ru/newdata/jsapi?action=location
	return fetch('https://smi2.ru/newdata/jsapi?action=location', {
		method: 'POST',
		mode: 'cors',
		body: JSON.stringify({
			block_id: 84683
		}),
		headers: new Headers({ 'content-type': 'application/json' })
	});
};
