'use client';

// стили
import style from './Links.module.sass';

// всякие некстовые штуки
import Link from 'next/link';

// хуки
import { useAppSelector } from '@/Hooks/redux';
import { useDispatch } from 'react-redux';

// actions
import { HeadingSlice } from '@/Redux/Slices/HeadingSlice/HeadingSlice';

// компоненты
import { SelectLi } from '@/Features/SelectLi/SelectLi';
import { useEffect, useState } from 'react';
import { GeoState } from '@/Redux/Slices/GeoSlice/GeoState';

import { usePathname } from 'next/navigation';
import { TranslateFunc } from '@/lib/translate';
export const Links = () => {
	// получение шрифта
	// const { CurrentFontSize } = useAppSelector(state => state.HeaderState);

	const Headings: any = useAppSelector(state => state.HeadingSlice);
	// redux приколы
	const dispatch = useDispatch();

	// actions

	// localNews
	const { SetcurrentLocalId } = HeadingSlice.actions;
	const Geo = useAppSelector(state => state.getPostLocation);
	const LocalNews: any = useAppSelector(state => state.getPostNewsLocal);
	const { setFinalSelect } = GeoState.actions;
	const { isSwitcherOn, FinalSelect, ClickLocal } = useAppSelector(
		state => state.GeoState
	);
	const { currentLocalId } = useAppSelector(state => state.HeadingSlice);
	const ArrNum = useAppSelector(state => state.getPostProperty);
	const [isContry, setisContry] = useState(false);
	const [stateSit, SetstateSity] = useState('');

	useEffect(() => {
		if (isSwitcherOn === true) {
			if (Geo.data.length > 0) {
				Geo.data.map((el: any, _, allArr: any) => {
					if (el.type === 'CITY') {
						dispatch(SetcurrentLocalId(el.geoname_id));
						dispatch(setFinalSelect(el.name));
						setisContry(false);
						SetstateSity(el.name);
					} else if (Geo.data.length == 2 && el.type === 'ADM1') {
						dispatch(SetcurrentLocalId(el.geoname_id));
						dispatch(setFinalSelect(el.name));
						setisContry(false);
						SetstateSity(el.name);
					} else if (Geo.data.length == 1 && el.type === 'COUNTRY') {
						dispatch(SetcurrentLocalId(el.geoname_id));
						dispatch(setFinalSelect(el.name));
						setisContry(false);
						SetstateSity(el.name);
					}
				});
			}
		} else if (isSwitcherOn === false) {
			ArrNum?.data?.locations !== undefined &&
				ArrNum?.data?.locations.map((el: any) => {
					if (el.name === FinalSelect) {
						SetstateSity(el.name);
						dispatch(SetcurrentLocalId(el.id));
						// SetcurrentGeoId(el.id);
						dispatch(setFinalSelect(el.name));
						setisContry(false);
					}
				});
		}
	}, [ArrNum, LocalNews.Loading, isSwitcherOn, FinalSelect]);

	const pathname = usePathname();
	const Test = pathname.split('/').at(-1);

	return (
		<>
			{/* // 	{Headings.Headings[0].map((el: any) => { */}
			{/* // return ( */}
			<ul
				className={style.NavMenuUnderNav}
				// style={{ fontSize: `${16 + CurrentFontSize}px` }}
			>
				<li className={style.LinkUnderNavFirst}>
					<Link
						className={style.LinkUnderNav}
						href={'/'}
						style={Test?.length === 0 ? { backgroundColor: '#4A4A4A' } : {}}
					>
						Главное
					</Link>
				</li>

				<li className={style.LinkUnderNavSecond}>
					<Link
						className={style.LinkUnderNav}
						href={'/publisher/2957'}
						style={Test == '2957' ? { backgroundColor: '#4A4A4A' } : {}}
					>
						Россия-Китай
					</Link>
				</li>

				{FinalSelect.length !== 0 ? (
					<li className={style.LinkUnderNavThird}>
						<Link
							style={
								Test == `${TranslateFunc(stateSit)}`
									? { backgroundColor: '#4A4A4A' }
									: {}
							}
							className={style.LinkUnderNav}
							href={`/region/${currentLocalId}/${TranslateFunc(stateSit)}`}
						>
							{FinalSelect}
						</Link>
					</li>
				) : (
					<></>
				)}

				<li className={style.LinkUnderNavFourth}>
					<Link
						style={Test == 'politics' ? { backgroundColor: '#4A4A4A' } : {}}
						className={style.LinkUnderNav}
						href={'/topic/politics?IdTopic=6'}
					>
						Политика
					</Link>
				</li>

				<li className={style.LinkUnderNavFifth}>
					<Link
						className={style.LinkUnderNav}
						href={'/topic/sports?IdTopic=5'}
						style={Test == 'sports' ? { backgroundColor: '#4A4A4A' } : {}}
					>
						Спорт
					</Link>
				</li>

				<li className={style.LinkUnderNavSixth}>
					<Link
						style={
							Test == 'nature-and-science' ? { backgroundColor: '#4A4A4A' } : {}
						}
						className={style.LinkUnderNav}
						href={'/topic/nature-and-science?IdTopic=1'}
					>
						Наука
					</Link>
				</li>

				<li className={style.LinkUnderNavSeventh}>
					<Link
						style={Test == 'business' ? { backgroundColor: '#4A4A4A' } : {}}
						className={style.LinkUnderNav}
						href={'/topic/business?IdTopic=3'}
					>
						Бизнес
					</Link>
				</li>

				<SelectLi />
			</ul>
		</>
	);
};
