'use client';
import Link from 'next/link';
import style from './nav.module.sass';
import { useAppSelector } from '@/Hooks/redux';
import { useEffect, useState } from 'react';
import { NavMobileSlice } from '@/Redux/Slices/NavMobileSlice/NavMobileSlice';
import { useDispatch } from 'react-redux';
import { usePathname } from 'next/navigation';
import { TranslateFunc } from '@/lib/translate';
import { GeoState } from '@/Redux/Slices/GeoSlice/GeoState';
import { HeadingSlice } from '@/Redux/Slices/HeadingSlice/HeadingSlice';
// ===========================================

export default function NavMobile() {
	// получеие данных redux
	const dispatch = useDispatch();
	const { SetPage } = NavMobileSlice.actions;
	const { currentPage } = useAppSelector(state => state.NavMobileSlice);
	const { isSwitcherOn, FinalSelect, ClickLocal } = useAppSelector(
		state => state.GeoState
	);
	const LocalNews: any = useAppSelector(state => state.getPostNewsLocal);
	const ArrNum = useAppSelector(state => state.getPostProperty);
	const { setFinalSelect } = GeoState.actions;
	const { SetcurrentLocalId } = HeadingSlice.actions;
	const { currentLocalId, Headings, isOpenBurger } = useAppSelector(
		state => state.HeadingSlice
	);
	const Array = [...Headings[0], ...Headings[1]];
	const [isOpen, setOpen] = useState(false);

	const openFunction = () => {
		if (isOpen === false) {
			setOpen(true);
		} else {
			setOpen(false);
		}
	};

	const Geo = useAppSelector(state => state.getPostLocation);
	const pathname = usePathname();
	// const { CurrentFontSize } = useAppSelector(state => state.HeaderState);
	const Test = pathname.split('/').at(-1);
	const [isContry, setisContry] = useState(false);
	const [stateSit, SetstateSity] = useState('');
	// ======================================

	useEffect(() => {
		if (isOpenBurger) {
			setOpen(false);
		}
	}, [isOpenBurger]);

	useEffect(() => {
		if (isSwitcherOn === true) {
			if (Geo.data.length > 0) {
				Geo.data.map((el: any, _, allArr: any) => {
					if (el.type === 'CITY') {
						dispatch(SetcurrentLocalId(el.geoname_id));
						dispatch(setFinalSelect(el.name));
						setisContry(false);
						SetstateSity(el.name);
					} else if (Geo.data.length == 2 && el.type === 'ADM1') {
						dispatch(SetcurrentLocalId(el.geoname_id));
						dispatch(setFinalSelect(el.name));
						setisContry(false);
						SetstateSity(el.name);
					} else if (Geo.data.length == 1 && el.type === 'COUNTRY') {
						dispatch(SetcurrentLocalId(el.geoname_id));
						dispatch(setFinalSelect(el.name));
						setisContry(false);
						SetstateSity(el.name);
					}
				});
			}
		} else if (isSwitcherOn === false) {
			ArrNum?.data?.locations !== undefined &&
				ArrNum?.data?.locations.map((el: any) => {
					if (el.name === FinalSelect) {
						SetstateSity(el.name);
						dispatch(SetcurrentLocalId(el.id));
						// SetcurrentGeoId(el.id);
						dispatch(setFinalSelect(el.name));
						setisContry(false);
					}
				});
		}
	}, [ArrNum, LocalNews.Loading, isSwitcherOn, FinalSelect]);
	return (
		<section className={style.Wrapper}>
			<div
				style={isOpenBurger ? { opacity: '0', transition: 'all .5s' } : {}}
				className={style.pageTitleWrapper}
				onClick={openFunction}
			>
				<div className={style.pageTitle}>{currentPage}</div>
				<svg
					className={isOpen ? style.arrowOpen : style.arrowDefault}
					width='10'
					height='6'
					viewBox='0 0 10 6'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<path d='M1 1L5 4L9 1' stroke='white' stroke-width='2'></path>
				</svg>
			</div>
			{isOpen ? (
				<section
					className={
						isOpen ? style.NavigationWrapperAnim : style.NavigationWrapper
					}
				>
					<ul
						className={style.List}
						// style={{ fontSize: `${16 + CurrentFontSize}px` }}
					>
						<li>
							<Link
								onClick={() => openFunction()}
								className={style.LinkNav}
								href={'/'}
								style={Test?.length === 0 ? { color: '#fff' } : {}}
							>
								Главное
							</Link>
						</li>

						<li>
							<Link
								onClick={() => openFunction()}
								className={style.LinkNav}
								href={'/publisher/2957'}
								style={Test == '2957' ? { color: '#fff' } : {}}
							>
								Россия-Китай
							</Link>
						</li>

						{FinalSelect.length !== 0 ? (
							<li className={style.LinkUnderNavThird}>
								<Link
									onClick={() => openFunction()}
									style={
										Test == `${TranslateFunc(stateSit)}`
											? { color: '#fff' }
											: {}
									}
									className={style.LinkNav}
									href={`/region/${currentLocalId}/${TranslateFunc(stateSit)}`}
								>
									{FinalSelect}
								</Link>
							</li>
						) : (
							<></>
						)}

						<li className={style.LinkUnderNavFourth}>
							<Link
								onClick={() => openFunction()}
								style={Test == 'politics' ? { color: '#fff' } : {}}
								className={style.LinkNav}
								href={'/topic/politics?IdTopic=6'}
							>
								Политика
							</Link>
						</li>

						<li className={style.LinkUnderNavFifth}>
							<Link
								onClick={() => openFunction()}
								className={style.LinkNav}
								href={'/topic/sports?IdTopic=5'}
								style={Test == 'sports' ? { color: '#fff' } : {}}
							>
								Спорт
							</Link>
						</li>

						<li className={style.LinkUnderNavSixth}>
							<Link
								onClick={() => openFunction()}
								style={Test == 'nature-and-science' ? { color: '#fff' } : {}}
								className={style.LinkNav}
								href={'/topic/nature-and-science?IdTopic=1'}
							>
								Наука
							</Link>
						</li>

						<li className={style.LinkUnderNavSeventh}>
							<Link
								onClick={() => openFunction()}
								style={Test == 'business' ? { color: '#fff' } : {}}
								className={style.LinkNav}
								href={'/topic/business?IdTopic=3'}
							>
								Бизнес
							</Link>
						</li>
						<li className={style.modalItem}>
							<Link
								onClick={() => openFunction()}
								style={
									Test == 'science-and-technology' ? { color: '#fff' } : {}
								}
								className={style.LinkNav}
								href={'/topic/science-and-technology?IdTopic=2'}
							>
								Технологии
							</Link>
						</li>
						<li className={style.modalItem}>
							<Link
								onClick={() => openFunction()}
								style={Test == 'automobile' ? { color: '#fff' } : {}}
								className={style.LinkNav}
								href={'/topic/automobile?IdTopic=14'}
							>
								Авто и мото
							</Link>
						</li>
						<li className={style.modalItem}>
							<Link
								onClick={() => openFunction()}
								style={Test == 'show-business' ? { color: '#fff' } : {}}
								className={style.LinkNav}
								href={'/topic/show-business?IdTopic=36'}
							>
								Шоу-бизнес
							</Link>
						</li>
						<li className={style.LinkUnderNavFourth}>
							<Link
								onClick={() => openFunction()}
								style={Test == '3043' ? { color: '#fff' } : {}}
								className={style.LinkNav}
								href={'/publisher/3043'}
							>
								Разное
							</Link>
						</li>
					</ul>
				</section>
			) : (
				<></>
			)}
		</section>
	);
}
