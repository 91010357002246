export const GetFinam = () => {
	return fetch('/api?action=currency', {
		method: 'POST',
		mode: 'cors',
		body: JSON.stringify({
			block_id: 84683
		}),
		headers: new Headers({ 'content-type': 'application/json' })
	});
};
